<template>
  <div>
    <div class="row">
      <div class="col">
        <button class="d-print-none btn btn-success my-2" @click="getXlsx()">
          <i class="fa fa-file-excel-o"></i> Descargar Excel
        </button>

        <div class="table-responsive bg-white">
          <table class="table table-sm table-bordered" ref="elTabla">
            <tr>
              <!-- <th colspan="4" class="text-center">
                REPORTE DE ATENCION A PACIENTES POR CIRUJANO DENTISTA
              </th> -->
              <th colspan="4" class="text-center">{{ firstEmployeeName }}</th>
              <th colspan="4" class="text-center">{{ dateRanges }}</th>
            </tr>
            <tr>
              <td class="d-print-none"></td>
              <td class="d-print-none"></td>
              <td colspan="7"></td>
              <td class="d-print-none"></td>
              <th class="text-center bg_mi_verde">
                <app-span-money
                  :moneyCode="1"
                  :quantity="total"
                ></app-span-money>
              </th>
            </tr>
            <tr class="table-secondary text-center">
              <!-- <td>N°</td>
            <td>DOCTOR</td> -->
              <td class="d-print-none">FECHA</td>
              <td class="d-print-none">HISTORIA</td>
              <td>PACIENTE</td>
              <td>TRATAMIENTO</td>
              <td>COSTO DEL TRATAMIENTO</td>
              <td>DSCTO % PACIENTE</td>
              <td>SUBTOTAL 1</td>
              <td>DSCTO LABORATORIO</td>
              <td>SUB TOTAL 2</td>
              <!-- <td>TOTAL</td> -->
              <!-- <td>OBSERVACION</td> -->
              <td class="bg_mi_verde text-nowrap d-print-none">% DOCTOR</td>
              <td class="bg_mi_verde">TOTAL</td>
            </tr>
            <tr class="text-center" v-for="(l, i) in list" :key="i">
              <!-- <td>{{ i + 1 }}</td>
            <td>{{ l.employee_name }}</td> -->
              <td class="d-print-none">{{ l.created_at | dateFormat }}</td>
              <td class="d-print-none">{{ l.treatment_plan_id }}</td>
              <td class="td_short">{{ l.client_name }}</td>
              <td>
                <span>{{ l.product_name }}</span>
                <span v-for="l1 in l.pieces" :key="l1">({{ l1 }}) </span>
              </td>
              <td>
                <!-- {{ l.original_price }} -->
                <app-span-money
                  :quantity="l.original_price"
                  :moneyCode="1"
                ></app-span-money>
              </td>
              <td>
                <!-- {{ l.discount / 100 }} -->
                <span>{{ (l.discount / 100) | percent }}</span>
              </td>
              <td>
                <!-- {{ l.unit_price }} -->
                <app-span-money
                  :quantity="l.unit_price"
                  :moneyCode="1"
                ></app-span-money>
              </td>
              <td>
                <!-- {{ l.cost }} -->
                <app-span-money
                  :quantity="l.cost"
                  :moneyCode="1"
                ></app-span-money>
              </td>
              <td>
                <!-- {{ l.unit_price - l.cost }} -->
                <app-span-money
                  :quantity="l.unit_price - l.cost"
                  :moneyCode="1"
                ></app-span-money>
              </td>
              <!-- <td></td> -->
              <!-- <td></td> -->
              <td class="d-print-none">
                <!-- {{ l.portion }} -->
                <span>{{ l.portion | percent }}</span>
              </td>
              <td>
                <!-- {{ l.amount }} -->
                <app-span-money
                  :quantity="l.amount"
                  :moneyCode="1"
                ></app-span-money>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-5">
        <!-- <letsPay ref="elLetsPay"></letsPay> -->
        <payDetailsContainer
          :employeeLiquidationId="employee_liquidation_id"
        ></payDetailsContainer>
        <!-- <stickForm
          :liquidationId="liquidation.liquidation_id"
          :moneyCode="1"
          :disabled="true"
        ></stickForm> -->
      </div>
    </div>
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
import _ from "lodash";
import { myMoment, momentDateFormat } from "../../utils/myMoment";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
// import stickForm from "../../admin-module/liquidations/overloads/StickForm";
import { GeneralService } from "../../general-module/GeneralService";

import payDetailsContainer from "../liquidations/payDetailsContainer";

export default {
  components: {
    payDetailsContainer
  },

  // directives
  // filters

  props: {
    employee_liquidation_id: {}
  },

  data: () => ({
    employee_name: "",
    list: [],
    liquidation: {}
  }),

  computed: {
    firstEmployeeName() {
      return _.head(this.list.map(x => x.employee_name));
    },
    dateRanges() {
      let aFechas = this.list.map(x =>
        myMoment(x.created_at).format(momentDateFormat)
      );
      return `${_.last(aFechas)} al ${_.head(aFechas)}`;
    },
    total() {
      return this.list
        .map(x => parseFloat(x.amount))
        .reduce((a, b) => a + b, 0);
    }
  },

  watch: {
    employee_liquidation_id: {
      immediate: true,
      handler() {
        this.getData();
      }
    }
  },

  mounted() {
    //
  },

  methods: {
    getXlsx() {
      var tbl = this.$refs.elTabla;
      var wb = XLSX.utils.table_to_book(tbl);
      var wopts = { bookType: "xlsx", bookSST: false, type: "binary" };
      var wbout = XLSX.write(wb, wopts);
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      saveAs(
        new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
        `${this.firstEmployeeName}_${this.dateRanges}.xlsx`
      );
    },
    getData() {
      GeneralService.getEmployeeLiquidation(this.employee_liquidation_id).then(
        reg => {
          this.liquidation = reg;
        }
      );

      DentalClinicService.getEmployeePayments({
        employee_liquidation_id: this.employee_liquidation_id
      }).then(list => {
        this.list = list;
        // console.log(list);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.bg_mi_verde {
  background-color: greenyellow;
}

@media print {
  .table td {
    font-size: 0.7rem;
  }
}
</style>
